import React from "react";

const Fallback = () => {
  return (
    <div class="bg-cyan-400 fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center">
      <div
        class="
      circle w-64 h-64 rounded-full
      border-solid 
      border-8
      border-t-cyan-300
      border-r-cyan-100
      border-b-cyan-100
      border-l-cyan-100
    "
      ></div>
    </div>
  );
};

export default Fallback;
